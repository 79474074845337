import React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import "../assets/scss/index.scss";

// ** Declare Theme Provider
const MaterialThemeProvider = ({ children }) => {
    const themeConfig = {
        palette: {
            mode: "dark",
            primary: {
                main: "#0b070d",
            },
            secondary: {
                main: "#b9a24c",
            },
            background: {
                default: "#0b070d",
                paper: "#0b070d",
            },
            common: {
                black: "#0b070d",
                white: "#fff",
                lightBlue: "#644d9c",
                darkBlue: "#644d9c",
            },
            white: {
                main: "#fff",
            },
            gray: {
                main: "#4f4f4f",
            },
        },
        shape: {
            borderRadius: 12,
        },
        typography: {
            fontFamily: "'DM Sans', sans-serif",
            fontSize: 14,
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    arrow: {
                        color: "#492b66",
                    },
                    tooltip: {
                        background: "#492b66 !important",
                        fontSize: 14,
                        color: "rgb(191, 191, 191)",
                        fontWeight: "100",
                    },
                },
            },
        },
    };

    const theme = createTheme(themeConfig);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};
export default MaterialThemeProvider;
